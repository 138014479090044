<template>
    <div class="wizard">
        <div class="intro topIntro position-relative talent-intro">
            <!---------------------------------------------------------->
            <div class="container position-relative">
                <nav class="navbar navbar-expand-xl landing-nav ps-2">
                    <router-link class="navbar-brand" to="/">
                        <img src="../../assets/images/logo/2Asset 1.svg" alt="" width="120" />
                    </router-link>

                    <div class="btn-group loggedin-dropdown" style="order: 2">
                        <LanguageSwitcher class="language-switcher-large" />
                        <button
                            type="button"
                            class="btn dropdown-toggle me-3"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            @click="toggleLogouteMenu()"
                        >{{ this.user.first_name }}</button>
                        <ul
                            :class="['dropdown-menu dropdown-menu-end logout-menu', this.isLogoutMenu ? 'show' : '']"
                            v-if="true"
                        >
                            <li>
                                <button
                                    class="dropdown-item"
                                    type="button"
                                    @click="goToProfile()"
                                >
                                    <i class="bi bi-person me-2"></i>
                                        {{$t('home.myProfile') }}
                                </button>
                            </li>
                            <li>
                                <button class="dropdown-item" type="button" @click="logOut()">
                                    <i class="bi bi-box-arrow-right me-2"></i>  {{$t('home.logout') }}
                                </button>
                            </li>
                        </ul>
                    </div>
                    <!--  <div class="login-btn" style="order: 3">
                    
                    </div> -->
      
                    <LanguageSwitcher separator-light has-divider divider-at-start class="me-2 ms-auto" />

                    <button
                        class="navbar-toggler light"
                        type="button"
                        @click="triggerMobileMenu = !triggerMobileMenu"
                    >
                    <!-- <span class="navbar-toggler-icon"> -->
                    <svg data-v-0315f6de="" width="20px" height="23px" viewBox="0 0 20 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g data-v-0315f6de="" id="Client" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g data-v-0315f6de="" id="02.01.squadio2-final-design" transform="translate(-1462.000000, -39.000000)"><g data-v-0315f6de="" id="Group-5" transform="translate(1462.000000, 39.000000)"><rect data-v-0315f6de="" id="Rectangle" x="0" y="0" width="20" height="2" rx="1"></rect><rect data-v-0315f6de="" id="Rectangle" x="0" y="8" width="20" height="2" rx="1"></rect><rect data-v-0315f6de="" id="Rectangle" x="0" y="16" width="20" height="2" rx="1"></rect></g></g></g></svg>
                    <!-- </span> -->
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul
                        class="mb-2 nav col-12 col-lg-auto me-lg-auto justify-content-center mb-md-0"
                    >
                        <li>
                            <router-link to="/" class="px-3 nav-link">
                                {{ $t("home.home") }}
                            </router-link>
                        </li>
                        <!-- <li>
                            <a href="#services-section" class="px-3 nav-link scrool">
                                {{ $t("home.services") }}
                            </a>
                        </li>
                        <li>
                            <a href="#steps" class="px-3 nav-link">
                                {{ $t("home.howItWorks") }}</a
                            >
                        </li>
                        <li>
                            <a href="#Partners-div" class="px-3 nav-link">
                                {{ $t("home.partners") }}</a
                            >
                        </li>
                        <li>
                            <a href="#words" class="px-3 nav-link">
                                {{ $t("home.successStories") }}</a
                            >
                        </li> -->
                    </ul>
                    </div>

                    <mobile-menu
                        :is-open="triggerMobileMenu"
                        wizard
                        @close-menu="menuClosed"
                    >
                    <li class="mobileMenu__item">
                        <router-link to="/">
                        {{ $t("home.home") }}
                        </router-link>
                    </li>
                    <!-- <li class="mobileMenu__item">
                        <router-link to="/#services-section">
                        {{ $t("home.services") }}
                        </router-link>
                    </li>
                    <li class="mobileMenu__item">
                        <router-link to="/#steps">
                        {{ $t("home.howItWorks") }}
                        </router-link>
                    </li>
                    <li class="mobileMenu__item">
                        <router-link to="/#Partners-div">
                        {{ $t("home.partners") }}
                        </router-link>
                    </li>
                    <li class="mobileMenu__item">
                        <router-link to="/#words">
                        {{ $t("home.successStories") }}
                        </router-link>
                    </li> -->
                    </mobile-menu>
                </nav>
            </div>
            <router-view></router-view>

            <div 
                class="absolute-icon parallax-item"
                data-paroller-factor="-0.3"
                data-paroller-type="foreground"
                v-if="this.$route.name != 'talentHome'&&!isWorkable()"
            >
                <img src="../../assets/images/top-stroke.svg" alt width="1000" />
            </div>
        </div>
    </div>
</template>

<script>
import LanguageSwitcher from "@/components/shared/languageSwitcher/languageSwitcher.vue";
import { mapGetters } from "vuex";
import Steps from "../../enums/WizzardSteps";
import MobileMenu from "@/components/MobileMenu";
import {isWorkable} from "../../utils/shared"
import './style.css';
export default {
    components: { LanguageSwitcher, MobileMenu },
    data() {
        return {
            triggerMobileMenu: false,
            isCongratModalVisible: false,
            isLogoutMenu: false
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/getUser"
        })
    },
    methods: {
        menuClosed() {
            this.triggerMobileMenu = false;
            const body = document.querySelector('body');
            body.classList.remove('is-fixed');
        },
        isWorkable,
        toggleLogouteMenu() {
            this.isLogoutMenu = !this.isLogoutMenu
        },
        logOut() {
            this.$store.dispatch("auth/logOut").then(() => {
               this.$router.push({ path: "/" });
            });
        },
        goToProfile() {
            if (this.user.step == Steps.PROFILE) {
                this.$router.push({ name: "Profile" })
            }
        },
        gotoHome(){
            this.$router.push({ path: "/" })
        },
        isProfile() {
            return this.user.step < Steps.PROFILE
        },
        isWizard() {
            return this.$route.name == "Wizard"
        }
    },
    mounted() {
        const body = document.querySelector('body');
        body.classList.remove('is-fixed');
    }
};
</script>
<style src="./talent.css"></style>
